<template>
  <a-layout class='index animated fadeIn'>
    <a-card class="mb10">
      <div class="mb15">
        <span class='mr15' @click='createSon'>
          <a-button type="primary">
            添加子菜单
          </a-button>
        </span>
        <span class='mr15' @click='createTop'>
          <a-button>
            <PlusOutlined /> 添加顶级菜单
          </a-button>
        </span>
        <span class='mr15'>
          <a-button @click='delbrandMenus'>
            <DeleteOutlined /> 批量删除
          </a-button>
        </span>
      </div>
      <a-layout>
        <a-row style="background-color:#fff;">
          <a-col :span="10">
            <a-alert message="该数据开发人员为系统生成的数据,无特殊情况请勿操作" type="warning" show-icon class='mb10' />
            <a-alert :message="'当前选择编辑：'+(selectedNodes.title?selectedNodes.title:'')" type="info" show-icon class='mb10' />
            <a-input-search class='mb10' placeholder="输入菜单名搜索" @search="onSearch" />
            <a-card title="" style="height:600px;margin-top:10px;overflow-Y:auto">
              <a-tree checkable :tree-data="treeData" v-model:expandedKeys="expandedKeys" v-model:selectedKeys="selectedKeys" v-model:checkedKeys="checkedKeys"
               @select="onSelect" @check="onCheck" :replace-fields="{key:'id'}">
              </a-tree>
            </a-card>
          </a-col>
          <a-col offset='1' :span="10">
            <a-card title="" style="min-height:690px">
              <myform :key='1'  :selectedNodes='selectedNodes' v-on:submit='onSubmit' />
            </a-card>
          </a-col>
        </a-row>
      </a-layout>
    </a-card>
  </a-layout>
  <a-modal v-model:visible="visible" title="新增菜单" :footer='null'  :loading="loading">
    <myform v-on:submit='onSubmit' :key='2' />
  </a-modal>
</template>
<script>
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { inject, onMounted, reactive, ref, toRefs } from "vue";
import myform from "./components/form.vue";
import { getbrandMenu,editbrandMenu,createbrandMenu,delbrandMenu } from "@/api/system";
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defForm = {
  title: "",
  hidden: false,
  type: "",
  key: "",
  icon: "",
  sort: null,
  component: "",
};
export default {
  components: { DeleteOutlined, PlusOutlined, myform },
  setup(props) {
    const form = ref({ ...defForm });
    const state = reactive({
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
      value: [],
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: [],
      visible: false,
      selectedNodes: {}, //点中的树形结构
      pid:0,
      loading:false
    });
    // 去出注入的工具
    const _lodash = inject("_lodash");
    const $message =inject('$message')
    const $Modal=inject('$Modal')
    // 获取菜单列表
     const treeData = ref([]);
    const getTree = () => {
      onMounted(async () => {
        const res = await getbrandMenu()
          .then((res) => res)
          .catch((error) => error);
        treeData.value = res.data.data;
      });
      return { treeData };
    };
    // 选中树形结构处理函数
    const onSelect = function (selectedKeys, { selected,selectedNodes }) {
      if (selected) {
        console.log(selectedNodes[0]["props"])
        state.selectedNodes = selectedNodes[0]["props"];
      } else {
        state.selectedNodes = [];
      }
    };
   // 修改弹窗 状态
    const visibleChange=(bool)=>{state.visible=bool}
    // 修改PID标识
    const pidChange=(num)=>{state.pid=num}
    // 添加子菜单
    const createSon=()=>{
        if(!_lodash.isEmpty(state.selectedNodes)){
          pidChange(state.selectedNodes.id)
          visibleChange(true)
      }else{
        $iscode({code:0,msg:"请选择上级菜单"},true)
      }
    }

    // 添加顶级菜单
    const createTop=()=>{
       pidChange(0)
       visibleChange(true)
    }
    // 定义一个id 集合 用于批量删除
    const  delId=ref()
    const onCheck=(checkedKeys,{checked,node,checkedNodes})=>{
      // lodash 方法 详情查官网
      delId.value=_lodash.map(_lodash.map(checkedNodes,"props"),'id').join(',')
    }
    // 删除菜单
    const delbrandMenus=()=>{
      if(!delId.value){
        $message.error('请勾选需要删除的菜单')
        return
      }
        $Modal.confirm({ content: '确认删除所选菜单',
          centered:true,onOk:()=>{
          delbrandMenu(delId.value).then(res=>{
            if($iscode(res.data,true)){
                state.checkedKeys=[]
                state.selectedNodes=[]
                getbrandMenu().then((res) => {treeData.value=res.data.data})
            }
          })
        }})
    }
    // 提交数据
    const onSubmit = function (result) {
      if(result.id){
        editbrandMenu(result.id,result).then(res=>{
         if ($iscode(res.data,true)) {
            getbrandMenu().then((res) => {treeData.value=res.data.data})
          } else {
            state.loading=true
          }
        })
      }else{
       state.loading=true
       createbrandMenu({...result,pid:state.pid}).then(res=>{
       if ($iscode(res.data,true)) {
            visibleChange(false)
            getbrandMenu().then((res) => {treeData.value=res.data.data})
          } else {
            state.loading=true
          }
       }).catch((error)=>{})
      }
    };
    return {
      form,
      ...toRefs(state),
      ...getTree(),
      onSelect,
      onSubmit,
      createSon,
      createTop,
      delbrandMenus,
      onCheck
    };
  },
};
</script>